.section-footer-body {
    background-color: #fff;
}

.section-footer-boxes {
    display: flex;
    flex-direction: row;
    position: relative; /* Necessário para posicionar corretamente o pseudo-elemento */
    align-content: center;
    background-image: radial-gradient(circle, #a0d12c, #96c428, #8cb824, #82ab21, #789f1d); /* Cor de fundo verde com gradiente */
    width: 100%;
    height: 200px;
    overflow: hidden; /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */
    border-radius: 50px 50px 0 0 ;
    margin: 0 auto;
    
}

.section-footer-boxes p {
    color: #fff;
    text-align: center; /* Centraliza o texto */
    margin: 0; /* Remove margens padrão */
}

.section-footer-boxes h1 {
    color: #fff;
    font-size: 28px;
}

.section-footer-boxes h5 {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
}

.section-footer-boxes .section-title {
    font-size: 20px;
    font-weight: bold;
}

.section-footer-boxes .section-subtitle {
    font-size: 15px;
    font-weight: 100;
}

.section-footer-boxes .left-footer-column {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    /* background-color: #2cb40a ; */
    overflow: hidden; /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */
}

.section-footer-boxes .left-footer-column img {
    width: 50%;
}

.section-footer-boxes .left-footer-column img:hover {
  width: 61%;
  transition: 0.3s;
}

.section-footer-boxes .middle-footer-column {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px 0px; /* row-gap column gap */
    /* background-color: #9400ce ; */
    overflow: hidden; /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */
}

.section-footer-boxes .right-footer-column {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    /* background-color: #0093b8 ; */
    overflow: hidden; /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */

}

ul {
  list-style: none;
}

.example-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.example-2 .icon-content {
  margin: 0 15px;
  position: relative;
}

.example-2 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #1C1B1C;
  padding: 6px 10px;
  border-radius: 15px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}

.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}

.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 20%;
  color: #fff;
  background-color: #34460a;
  transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 50%);
}

.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
}

.example-2 .icon-content a:hover {
  color: white;
}

.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover .filled {
  height: 100%;
}

.example-2 .icon-content a[data-social="whatsapp"] .filled,
.example-2 .icon-content a[data-social="whatsapp"] ~ .tooltip {
  background-color: #1db954;
}

.example-2 .icon-content a[data-social="instagram"] .filled,
.example-2 .icon-content a[data-social="instagram"] ~ .tooltip {
  background-color: #ea4c89;
}

.example-2 .icon-content a[data-social="facebook"] .filled,
.example-2 .icon-content a[data-social="facebook"] ~ .tooltip {
  background-color: #0377b1;
}

.example-2 .icon-content a[data-social="assinante"] .filled,
.example-2 .icon-content a[data-social="assinante"] ~ .tooltip {
  background-color: #ff7b00;
}
